import React from "react"
import "./traject.scss";

import Layout from "../components/layout"
import SEO from "../components/seo"
import Prices from "../components/prices"

const SecondPage = () => (
  <Layout>
    <SEO
      title="Persoonlijke ontwikkeling"
      description="Door drijveren-analyses, systemische opstellingen en stressanalyses werkt de gepatenteerde Zipperr® methode aan persoonlijke ontwikkeling en zakelijke groei"
    />
    <div
      className='containerPad bgalt'
    >
        <h1 style={{textAlign: 'center'}}>Kies voor een Zipperr® traject voor persoonlijke ontwikkeling en zakelijke groei</h1>
        <Prices/>
    </div>
  </Layout>
)

export default SecondPage
